<template>
  <div>
    <div :class="$style.section" v-for="summary of summaryElements" :key="summary.id">
      <h4 :class="$style.sectionTitle">{{ summary.title }}</h4>
      <p :class="$style.sectionContent">{{ summary.content }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent, toRefs, computed } from 'vue';
import { useI18n } from 'vue-i18n';

import datesUtils from '@/utils/dates.utils';
import companySubscriptionTypesConstants from '@/constants/companySubscriptionTypes.constants';

const SubscriptionSummary = defineComponent({
  props: {
    subscription: {
      type: Object,
      required: true,
    },
    subscriptionTypeSelector: {
      type: Function,
      required: true,
    },
    priceSelector: {
      type: Function,
      required: false,
    },
    startDateSelector: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const { startDateSelector, priceSelector, subscriptionTypeSelector, subscription } = toRefs(
      props,
    );

    const { t } = useI18n();

    const summaryElements = computed(() => {
      const subscriptionTypeId = subscriptionTypeSelector.value(subscription.value);
      const subscriptionDate = startDateSelector.value(subscription.value);

      const history = [
        {
          id: 1,
          title: t('components.subscriptionSummary.type'),
          content: t(`companySubscriptionTypes.${subscriptionTypeId}`),
        },
        {
          id: 3,
          title: t('components.subscriptionSummary.startDateTitle'),
          content: datesUtils.formatToSimpleDate(subscriptionDate),
        },
      ];

      if (priceSelector.value) {
        const price = priceSelector.value(subscription.value);

        const contentKey = (
          price === 0 && subscriptionTypeId === companySubscriptionTypesConstants.LOYALTY
        ) 
          ? `components.subscriptionSummary.priceRecurrenceSpecial.${subscription.value.subscriptionRecurrence}`
          : `components.subscriptionSummary.priceRecurrence.${subscription.value.subscriptionRecurrence}`;

        history.push({
          id: 2,
          title: t('components.subscriptionSummary.priceTitle'),
          content: t(contentKey, [price]),
        });
      }

      return history.sort((a, b) => a.id - b.id);
    });

    return {
      summaryElements,
    };
  },
});

export default SubscriptionSummary;
</script>

<style lang="scss" module>
@import '@/assets/styles/common/variables';
@import '@/assets/styles/common/mixins';

.section {
  padding: 1rem 2rem;
  border: 1px solid $gray;

  text-align: center;

  &:first-of-type {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  &:last-of-type {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  &+.section {
    border-top: none;
  }
}

.sectionTitle {
  font-family: 'Roboto';
  font-weight: normal;
  text-transform: none;
  color: $dark-gray;
  font-size: 1.6rem;
}

.sectionContent {
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 0.5rem;
}
</style>
