<template>
  <div :class="$style.historyContainer">
    <h3 class="mb-2">{{ t('components.subscriptionDetails.subscriptionHistory') }}</h3>

    <ul class="mb-2" v-if="history.length">
      <li
        v-for="invoice of history"
        :key="invoice.invoiceId"
        :class="[sectionClass, $style.invoice]"
      >
        <span :class="[$style.invoiceStatus, $style[invoice.status]]">
          {{ t(`components.subscriptionHistory.invoiceStatus.${invoice.status}`) }}
        </span>
        <span>{{ formatDate(invoice.periodStart) }} - {{ formatDate(invoice.periodEnd) }}</span>

        <a
          class="link"
          :href="invoice.invoiceUrl"
          target="_blank"
          rel="noreferrer noopener nofollow"
          >{{ t('components.subscriptionHistory.openInvoice') }}</a
        >
      </li>
    </ul>

    <p v-if="!history.length">
      {{ t('components.subscriptionHistory.empty') }}
    </p>

    <Pagination
      v-if="history.length"
      :class="[$style.pages, 'mt-2']"
      :current="currentPage"
      :total="totalPages"
      @go-next="goNextPage"
      @go-previous="goPreviousPage"
      @go-first="currentPage = 1"
      @go-last="currentPage = totalPages"
    />
  </div>
</template>

<script>
import { defineComponent, toRefs, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';

import Pagination from '@/components/common/Pagination.vue';
import datesUtils from '@/utils/dates.utils';

const SubscriptionHistory = defineComponent({
  components: {
    Pagination,
  },
  props: {
    pagedSubscriptionHistory: {
      type: Object,
      required: true,
    },
    sectionClass: {
      type: String,
      required: false,
    },
  },
  emits: ['pageChange'],
  setup(props, { emit }) {
    const { pagedSubscriptionHistory } = toRefs(props);

    const toast = useToast();
    const { t } = useI18n();

    const history = ref([]);
    const currentPage = ref(1);
    const totalPages = ref(1);
    const totalRecords = ref(1);

    watch(currentPage, (value, oldValue) => {
      if (value === oldValue) {
        return;
      }

      emit('pageChange', value);
    });

    const handleHistoryChange = () => {
      const {
        success,
        data,
        pageNumber,
        totalPages: historyTotalPages,
        totalRecords: historyTotalRecords,
      } = pagedSubscriptionHistory.value;

      if (!success) {
        // TODO add text into translation file
        toast.error(t('components.subscriptionHistory.errorLoadingHistory'));

        return;
      }

      history.value = data;
      currentPage.value = pageNumber;
      totalPages.value = historyTotalPages;
      totalRecords.value = historyTotalRecords;
    };

    watch(pagedSubscriptionHistory, handleHistoryChange, { immediate: true });

    const goNextPage = () => {
      currentPage.value = Math.min(currentPage.value + 1, totalPages.value);
    };

    const goPreviousPage = () => {
      currentPage.value = Math.max(currentPage.value - 1, 1);
    };

    return {
      t,
      history,
      formatDate: datesUtils.formatToSimpleDate,
      currentPage,
      totalPages,
      goNextPage,
      goPreviousPage,
    };
  },
});

export default SubscriptionHistory;
</script>

<style lang="scss" module>
@import '@/assets/styles/common/variables';
@import '@/assets/styles/common/mixins';

.historyContainer {
  @include ipad {
    height: 100%;
    display: flex;
    flex-direction: column;

    .pages {
      margin-top: auto;
    }
  }
}

.invoice {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.invoiceStatus {
  margin-bottom: 0.5rem;

  padding: 0 0.8rem;
  border-radius: 20px;
  color: $white;

  &.paid {
    background-color: $main-color;
  }

  &.unpaid {
    background-color: $error;
  }
}</style>
