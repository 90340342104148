<script setup>
import { ref, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faUserCircle, faInfoCircle } from '@fortawesome/pro-light-svg-icons';

import useAuthenticatedUser from '@/composables/useAuthenticatedUser';
import useCurrentCompany from '@/composables/useCurrentCompany';
import companySubscriptionsService from '@/services/companySubscriptions.service';
import datesUtils from '@/utils/dates.utils';
import subscriptionTypesConstants from '@/constants/companySubscriptionTypes.constants';
import routesConstants from '@/constants/routes.constants';
import LinkButton from '@/components/common/LinkButton.vue';
import Button from '@/components/common/Button.vue';
import Modal from '@/components/common/Modal.vue';
import Loader from '@/components/common/Loader.vue';
import Layout from '@/components/common/Layout.vue';
import companyOffersConstants from '@/constants/companyOffers.constants';

import SubscriptionHistory from './SubscriptionHistory.vue';
import SubscriptionSummary from './SubscriptionSummary.vue';
import useCompanyOffers from '@/composables/useCompanyOffers';

const toast = useToast();
const { t } = useI18n();

const { currentCompanyId } = useCurrentCompany();
const { username } = useAuthenticatedUser();
const {
  subscription: companySubscription,
  fetchSubscription: fetchCompanySubscription,
  useLoyaltyCard: isMember,
} = useCompanyOffers();

const loading = ref(false);
const showCancelConfirmation = ref(false);
const subscriptionHistory = ref(null);
const subscriptionEndDate = ref(null);
const futureSubscriptionInfo = ref(null);

const showHistory = computed(() => {
  if (!isMember.value) {
    return false;
  }

  return !!subscriptionHistory.value?.data.length;
});

watch(showCancelConfirmation, (value) => {
  if (!value) {
    futureSubscriptionInfo.value = null;

    return;
  }

  const fetchFutureSubscriptionInfo = async () => {
    try {
      futureSubscriptionInfo.value = await companySubscriptionsService.fetchFutureSubscription(
        currentCompanyId.value,
        subscriptionTypesConstants.FREE,
      );
    } catch (err) {
      const [error] = err;

      toast.error(t(error));
    }
  };

  fetchFutureSubscriptionInfo();
});

const fetchSubscriptionHistory = async (page = 1) => {
  try {
    loading.value = true;
    subscriptionEndDate.value = null;

    const {
      shouldWeStopSubscription,
      subscriptionHistory: companySubscriptionHistory,
      subscriptionStopAt,
    } = await companySubscriptionsService.fetchCompanySubscriptionHistory(
      currentCompanyId.value,
      page,
    );

    if (shouldWeStopSubscription) {
      subscriptionEndDate.value = subscriptionStopAt;
    }

    subscriptionHistory.value = companySubscriptionHistory;
  } catch (err) {
    const [error] = err;

    toast.error(t(error));
  } finally {
    loading.value = false;
  }
};

watch(currentCompanyId, () => fetchSubscriptionHistory(), { immediate: true });

const handleStopSubscription = async () => {
  try {
    await companySubscriptionsService.cancelSubscription(currentCompanyId.value);

    showCancelConfirmation.value = false;
    fetchSubscriptionHistory();
    fetchCompanySubscription(currentCompanyId.value);
  } catch (err) {
    const [error] = err;

    toast.error(t(error));
  }
};

const handleStopCancellation = async () => {
  try {
    await companySubscriptionsService.cancelSubscriptionCancellation(currentCompanyId.value);

    fetchSubscriptionHistory();
    fetchCompanySubscription(currentCompanyId.value);
    toast.success(t('components.subscriptionDetails.subscriptionReEnabled'));
  } catch (err) {
    const [error] = err;

    toast.error(t(error));
  }
};

const formatDate = datesUtils.formatToSimpleDate;
</script>

<template>
  <Layout>
    <div :class="$style.container">
      <section
        :class="[
          $style.parentSection,
          $style.currentSubscription,
          {
            [$style.noMember]: !isMember,
            [$style.noHistory]: subscriptionHistory && !showHistory,
          },
        ]"
      >
        <h3 class="mb-2">{{ t('components.subscriptionDetails.currentSubscription') }}</h3>
        <FontAwesomeIcon :class="$style.userIcon" :icon="faUserCircle" />
        <p class="mb-1">{{ username }}</p>
        <p class="mb-2" v-if="subscriptionEndDate">
          {{
            t('components.subscriptionDetails.subscriptionCancellationAt', [
              formatDate(subscriptionEndDate),
            ])
          }}
        </p>
        <div :class="$style.tooltipContainer" >
          <FontAwesomeIcon :class="$style.infoIcon" :icon="faInfoCircle"/>
          <span :class="$style.tooltipText">
            {{ t('components.subscriptionDetails.subscriptionInformation') }}
          </span>
        </div>
        <template v-if="!isMember">
          <LinkButton
            class="mb-2"
            :to="`${routesConstants.OFFERS}?type=${companyOffersConstants.LOYALTY_CARD}`"
          >
            {{ t('components.subscriptionDetails.subscribeToLoyaltyCard') }}
          </LinkButton>
        </template>
        <SubscriptionSummary
          v-if="companySubscription"
          :subscription="companySubscription"
          :subscriptionTypeSelector="(s) => s.subscriptionTypeId"
          :startDateSelector="(s) => s.subscriptionDate"
          :priceSelector="isMember ? (s) => s.price : null"
        />
      </section>
      <section
        v-if="subscriptionHistory && showHistory"
        :class="[$style.parentSection, $style.history, { [$style.noMember]: !isMember }]"
      >
        <SubscriptionHistory
          v-if="subscriptionHistory"
          :pagedSubscriptionHistory="subscriptionHistory"
          :sectionClass="$style.section"
          @page-change="fetchSubscriptionHistory"
        />
      </section>
      <section v-if="isMember" :class="[$style.parentSection, $style.handleSub]">
        <h3 class="mb-1">{{ t('components.subscriptionDetails.handleSubscription') }}</h3>
        <template v-if="isMember && !companySubscription.shouldWeStopSubscription">
          <Button
            link-like
            :buttonClass="$style.cancelSubButton"
            @buttonClick="showCancelConfirmation = true"
          >
            {{ t('components.subscriptionDetails.stopSubscription') }}
          </Button>
        </template>
        <template v-if="isMember && companySubscription.shouldWeStopSubscription">
          <Button :class="$style.stopCancellationButton" @buttonClick="handleStopCancellation">
            {{ t('components.subscriptionDetails.cancelStopSubscription') }}
          </Button>
        </template>
      </section>
      <Modal :isDisplayed="showCancelConfirmation" @close-modal="showCancelConfirmation = false">
        <h3 class="mb-2">
          {{ t('components.subscriptionDetails.stopSubscriptionConfirmationTitle') }}
        </h3>
        <p v-html="t('components.subscriptionDetails.stopSubscriptionConfirmationText')" />
        <div class="mt-2">
          {{ t('components.subscriptionDetails.futureSubscriptionSummary') }}
        </div>
        <SubscriptionSummary
          v-if="futureSubscriptionInfo"
          class="mt-1"
          :subscription="futureSubscriptionInfo"
          :subscriptionTypeSelector="() => subscriptionTypesConstants.FREE"
          :startDateSelector="(s) => s.periodEnd"
          :priceSelector="(s) => s.newPrice"
        />
        <Loader v-if="!futureSubscriptionInfo" />
        <div v-if="futureSubscriptionInfo" :class="[$style.cancelConfirmationButtons, 'mt-3']">
          <Button isDanger @buttonClick="handleStopSubscription">
            {{ t('common.confirm') }}
          </Button>
          <Button @buttonClick="showCancelConfirmation = false">
            {{ t('common.cancel') }}
          </Button>
        </div>
      </Modal>
    </div>
  </Layout>
</template>

<style lang="scss" module>
@import '@/assets/styles/common/variables';
@import '@/assets/styles/common/mixins';

.container {
  @include ipad {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}

.userIcon {
  font-size: 16rem;

  margin-bottom: 1.5rem;
}

.tooltipContainer {
  position: relative;
  display: inline-block;

  .infoIcon {
    color: #555;
    font-weight: bold;
    cursor: pointer;
  }

  .tooltipText {
    display: none;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
    z-index: 1;
  }

  &:hover .tooltipText {
    display: block;
  }
}

.parentSection,
.section {
  padding: 2rem;
  border: 1px solid $gray;

  text-align: center;

  &:first-of-type {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    @include ipad {
      &.noMember {
        border-bottom-left-radius: 20px;
      }
    }
  }

  &:last-of-type {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    @include ipad {
      &.noMember {
        border-bottom-right-radius: 20px;
      }
    }
  }
}

.parentSection:first-of-type,
.parentSection:last-of-type {
  @include ipad {
    border-radius: 0;
  }
}

.section {
  padding: 1rem 2rem;
}

.section + .section,
.parentSection + .parentSection {
  border-top: none;
}

.parentSection + .parentSection {
  @include ipad {
    border-top: 1px solid $gray;
  }
}

.sectionTitle {
  font-family: 'Roboto';
  font-weight: normal;
  text-transform: none;
  color: $dark-gray;
  font-size: 1.6rem;
}

.sectionContent {
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 0.5rem;
}

.cancelSubButton {
  font-size: 1.6rem;
  font-weight: normal;
}

.modalButtons {
  @include spacing-children('vertical', 1rem);

  @include xs {
    display: flex;

    @include spacing-children('vertical', 0);
    @include spacing-children('horizontal', 1rem);
  }

  div {
    flex-grow: 1;
  }

  a,
  button {
    width: 100%;
    font-size: 1.4rem;

    @include iphone-6 {
      font-size: 1.6rem;
    }

    @include xs {
      font-size: 2rem;
    }
  }
}

@include ipad {
  .parentSection {
    border: 1px solid $gray;

    &.currentSubscription {
      width: 50%;
      border-top-left-radius: 20px;

      &.noHistory {
        width: 100%;

        border-radius: 20px;
      }
    }

    &.history {
      width: 50%;
      border-left: none;
      border-top-right-radius: 20px;
    }

    &.handleSub {
      width: 100%;
      border-top: none;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
}

.stopCancellationButton button {
  font-size: 1.4rem;

  @include ipad {
    font-size: 2rem;
  }
}

.cancelConfirmationButtons {
  display: flex;
  justify-content: flex-end;

  @include spacing-children('horizontal', 1rem);
}
</style>
